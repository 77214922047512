import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
	Card,
	CardContent,
	CardMedia,
	Checkbox,
	FormControlLabel,
	Grid,
	Link,
	Badge,
	Typography
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NoteCard from '../../notes/_note_card';
import SpeedDials from './_speed_dials'
import LockIcon from '@material-ui/icons/Lock';
import Alert from '@material-ui/lab/Alert';
import NumericInput from 'react-numeric-input';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

export default function MediaControlCard({product, callBack, purchased}) {
	const classes = useStyles();
	const [maxSelect, setMaxSelect] = React.useState(product.qty);
	const [state, setState] = React.useState({
		notes: product.notes,
		locked: product.locked,
		qty: purchased?.qty || product.qty,
		selected: purchased,
		disabled: false
	});

	const handleDeleteItem = () => {
		callBack({selected: false, order_item_id: product.order_item_id, order_item_stage_id: product.order_item_stage_id, deleted: true})
		setState({disabled: true})
	}

	const handleCheckBoxChange = (event) => {
		setState({...state, [event.target.name]: event.target.checked});
		callBack({
			selected: event.target.checked,
			qty: product.qty,
			order_item_id: product.order_item_id,
			order_item_stage_id: product.order_item_stage_id,
			price: product.price,
			shipping_to_hub: product.shipping_to_hub
		})
	}

	const handleQtyChange = (value) => {
		if (value > maxSelect || value <= 0 || value === null) return
		setState({...state, qty: value});
		callBack({
			selected: true,
			qty: value,
			order_item_id: product.order_item_id,
			order_item_stage_id: product.order_item_stage_id,
			price: product.price,
			shipping_to_hub: product.shipping_to_hub
		})
	};

	const handleNewNote = (new_note) => {
		var newNotes = [{user: 'You', gender: 'Male', message: new_note, date: ''}].concat(state.notes);
		setState({...state, notes: newNotes})
	}

	const handleLockStateChange = (new_state) => {
		setState({...state, locked: new_state})
		callBack({
			selected: false,
			qty: 0,
			order_item_id: product.order_item_id,
			order_item_stage_id: product.order_item_stage_id,
			price: product.price,
			shipping_to_hub: product.shipping_to_hub,
			locked: new_state
		})
	}

	const customization = product.customizations.map(c =>
	{if (c.value !== null && c.value !== '')
		return (<Typography variant='subtitle1' color='textSecondary' key={c.name}>
			{c.name}: <b>{c.value}</b>
		</Typography>)
		})

	return (
		<Card className={classes.root} hidden={state.disabled}>
			<CardMedia className={classes.cover}>
				<Badge badgeContent='DG' invisible={!product.dg} overlap="circular" color="error">
					<img src={product.image} alt={product.name} style={{height: '250px', width: '200px', objectFit: 'contain'}} />
				</Badge>{product.images && (
				<p>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              data-bs-whatever="@mdo"
              style={{
                backgroundColor: "transparent",
                color: "grey",
                height: "50px",
				width: "50px",
                borderColor: "transparent",
                borderRadius: "10px",
                marginTop: "20px",
                marginLeft: "5px",
              }}
            >			
<PhotoCameraIcon/>
              
            </button>
          </p>
        )}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Product Screenshots
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div
                  id="carouselExampleIndicators"
                  class="carousel carousel-dark slide"
                  data-bs-ride="carousel"
                >
                  <div class="carousel-indicators">
                    {product.images?.map((image, index) => (
                      <button
                        key={index}
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={index.toString()}
                        className={index === 0 ? "active" : ""}
                        aria-current={index === 0}
                        aria-label={`Slide ${index + 1}`}
                      ></button>
                    ))}
                  </div>
                  <div class="carousel-inner">
                    {product.images?.map((image, index) => (
                      <div
                        key={index}
                        className={`carousel-item ${
                          index === 0 ? "active" : ""
                        }`}
                      >
                        <img
                          src={image}
                          className="d-block w-100"
                          alt={`Slide ${index + 1}`}
                        />
                      </div>
                    ))}
                  </div>
                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div class="modal-footer"></div>
            </div>
          </div>
        </div>
</CardMedia>
			<div className={classes.details}>
				<CardContent className={classes.content}>
					<Typography component='h5' variant='h5'>
						<Link href={product.url} rel='noopener noreferrer' target='_blank'>
							{product.name}
						</Link>
					</Typography>
					<Grid container>
						<Grid item xs={6}>
							{product.prohibited && <Alert variant="filled" severity="error">This item could be Prohibited — please check it out!</Alert>}
							<Grid container>
								<Grid item xs={6}>
									<Typography variant='subtitle1' color='textSecondary'>
										Original qty: <b>{product.original_qty}</b>
									</Typography>
									<Typography variant='subtitle1' color='textSecondary'>
										Processed: <b>{product.processed}</b>
									</Typography>
									<Typography variant='subtitle1' color='textSecondary'>
										Remaining qty: <b>{product.qty}</b>
									</Typography>
									<Typography variant='subtitle1' color='textSecondary'>
										Price: <b>${product.price}</b>
									</Typography>
									{product.shipping_to_hub !== 0 && 
										<Typography variant='subtitle1' color='textSecondary'>
											Local shipping: <b>${product.shipping_to_hub}</b>
										</Typography>
									}
									
								</Grid>
								<Grid item xs={6}>
									{product.adjustment !== 0 && 
										<Typography variant='subtitle1' color='textSecondary'>
											Adjustment fee: <b>${product.adjustment}</b>
										</Typography>
									}
									<Typography variant='subtitle1' color='textSecondary'>
										Customs fees: <b>${product.customs_fees}</b>
									</Typography>
									{product.insurance !== 0 && 
										<Typography variant='subtitle1' color='textSecondary'>
											Insurance: <b>${product.insurance}</b>
										</Typography>
									}
									{product.dangerous_fee !== 0 && 
										<Typography variant='subtitle1' color='textSecondary'>
											DG fees: <b>${product.dangerous_fee}</b>
										</Typography>
									}
									{product.vendor_shipping &&
										<Typography variant='subtitle1' color='textSecondary'>
										Vendor shipping: <b>${product.vendor_shipping}</b>
									</Typography>
									}
									<Typography variant='subtitle1' color='textSecondary'>
										Shipping fees: <b>${product.shipped_to_final_destination}</b>
									</Typography>
									<Typography variant='subtitle1' color='textSecondary'>
										Docking fee: <b>${product.docking_fee}</b>
									</Typography>
									<Typography variant='subtitle1' color='textSecondary'>
										Ship By: <b>{product.shipping_option}</b>
									</Typography>
									{product.category_weight && 
										<Typography variant='subtitle1' color='textSecondary'>
											Category weight: <b>{product.category_weight}</b>
										</Typography>
									}
									{product.review_weight && 
										<Typography variant='subtitle1' color='textSecondary'>
											Review weight: <b>{product.review_weight}</b>
										</Typography>
									}
									{product.review_dimensions && 
										<Typography variant='subtitle1' color='textSecondary'>
											Review dimensions: <b>{product.review_dimensions}</b>
										</Typography>
									}
									{product.review_weight_liquid && 
											<Typography variant='subtitle1' color='textSecondary'>
											Review weight liquid: <b>{product.review_weight_liquid}</b>										</Typography>
}
									<Typography variant='subtitle1' color='textSecondary'>
										Predict: <b>{product.predict}</b>
									</Typography>
									{product.predict_weight && 
										<Typography variant='subtitle1' color='textSecondary'>
											predict weight : <b>{product.predict_weight}</b>
										</Typography>
									}
									{product.predict_dimensions && 
										<Typography variant='subtitle1' color='textSecondary'>
											Predict Dimension : <b>{product.predict_dimensions}</b>
										</Typography>
									}
									{product.review_weight && 
										<Typography variant='subtitle1' color='textSecondary'>
											Review Weight: <b>{product.review_weight}</b>
										</Typography>
									}
								</Grid>
							</Grid>
							{customization}

							{state.locked ?
									<LockIcon/>
								:
								<Grid container spacing={2}>
									<Grid item xs={1} container  direction="column" display="flex" justify="center">
										<FormControlLabel
											control={<Checkbox icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 30 }}/>}
																				checkedIcon={<CheckBoxIcon style={{fill: '#f6b67f', fontSize: 30}}/>}
																				name='selected'
																				defaultChecked={state.selected}
																				onChange={handleCheckBoxChange}/>}
										/>
									</Grid>
									<Grid item xs={3} container  direction="column" display="flex" justify="center" >
										<NumericInput min={1} max={maxSelect} value={state.qty} mobile size={4} onChange={handleQtyChange} name='qty' disabled={!state.selected}/>
									</Grid>
								</Grid>
							}</Grid>
			
						<Grid item xs={6}>
							<div>
								{state.notes && state.notes.length > 0 && <NoteCard notes={state.notes}/>}
							</div>
						</Grid>
					</Grid>
				</CardContent>
			</div>
			<div className='float-end'>
				<SpeedDials
					product={product}
					newNote={handleNewNote}
					handleDeleteItem={handleDeleteItem}
					callBackLockState = {handleLockStateChange}/>
			</div>
		</Card>

	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		padding: 20,
		margin: 20,
		wordBreak: 'break-all'
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		width: 250,
		height: 250,
		objectFit: 'contain'
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	playIcon: {
		height: 38,
		width: 38,
	},
}));
